/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

function RotatingObject() {
  const { nodes, materials } = useGLTF("/ME109.gltf");
  const myMesh = React.useRef();

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime() / 5;
    myMesh.current.rotation.y = a;
  });

  return (
    <mesh ref={myMesh}>
      <mesh
        geometry={nodes.Cylinder.geometry}
        material={materials.Material}
        position={[0, 0.04, 2.35]}
        rotation={[Math.PI / 2, -1.08, 0]}
        scale={[0.05, 0.03, 0.05]}
      />
    </mesh>
  );
}
export default function Model(props) {
  return (
    <group {...props} dispose={null}>
      <RotatingObject />
    </group>
  );
}

useGLTF.preload("/ME109.gltf");
